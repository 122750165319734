@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400&display=swap);
.menu{
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    color: black;
    background-color: #92AD8B;
}

.menu-title{
    display: flex;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    align-items: center;
}

.pet-logo {
    padding: 2%;
}

.menu-links {
    display: flex;
    align-items: center;
    margin-right: 2%;
    font-weight: bold;
}

.menutext{
    color: black !important;
    padding-right: 20px;
}

.menutext:hover {
    transform: scale(1.05);
    text-shadow: white 0px 0px 5px ;
}

.card{
    width: 75%; 
    margin-top: 20px; 
    margin-left: 20px;
    margin-bottom: 30px;
    border: none !important;
    border-radius: 25px;
    display: flex;
    justify-content: center; 
}

.card-header{
    background-color: #92AD8B !important;
    color: white;
    line-height: 20px;
    border-radius: 20px 20px 0px 0px !important;
}

li:last-child{
    border-radius: 0px 0px 20px 20px !important;
}

.list-group-item {
    line-height: 30px;
}

li:nth-child(2n+1){
    background-color: #E8E8E8 !important;
}

li:nth-child(2n+0){
    background-color: #F5F5F5 !important;
}

a{
    color: black !important;
}
a:link{
    text-decoration: none !important;
}

.sticky-top {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s ease-in-out;
    width: 50%;
    height: 90vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.chart-container {
    background-color: #f5f5f5;
    border-radius: 15px;
    border: 1px solid gray;
    width: 90%;
    margin-left: 5%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.chart-title {
    font-size: large;
}

.title-subtitle {
    max-width: 95%;
    padding-left: 5%;
    height: 20%;
    /* font-size: small; */
}

.chart {
    max-width: 95%;
    height: 100%;
}

.py-1{
    height: 100%;
}

.ocult-chart{
    width: 0;
    visibility: hidden;
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .col-lg-7 {
        padding: 0 !important
    }
    .chart-container {
        border: 0;
        width: 100%;
        margin-left: 0;
        border-radius: 0;
    }
    .chart-title {
        font-size: 20px;
        font-weight: bold
    }
    .x-icon {
        display: none
    }
    .title-subtitle {
        width: 100%;
    }
    .chart {
        padding-left: 5%
    }
}
* {
    font-family: 'Roboto Mono', monospace;
}

.main-container {
    background-color: #3B3B3B;
}

.container-fluid{
    height: 90vh;
    width: 100vw;
}

.row{
    height: 100%;
    display: flex;
    justify-content: center;
}

.col-lg-5 {
    max-height: 98%;
    overflow: auto;
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 2%;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
  background: #3B3B3B;
}

::-webkit-scrollbar-thumb {
  height: 10vh;
  background-color: #888;
  border-radius: 10px;
  border: none;
}

