@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400&display=swap');

* {
    font-family: 'Roboto Mono', monospace;
}

.main-container {
    background-color: #3B3B3B;
}

.container-fluid{
    height: 90vh;
    width: 100vw;
}

.row{
    height: 100%;
    display: flex;
    justify-content: center;
}

.col-lg-5 {
    max-height: 98%;
    overflow: auto;
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 2%;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
  background: #3B3B3B;
}

::-webkit-scrollbar-thumb {
  height: 10vh;
  background-color: #888;
  border-radius: 10px;
  border: none;
}
